<template>

<app-panel v-on:close="$emit('close')">
		
	<app-panel-title text="Assign add-on" />

	<div class="assign-box-head">

		<div class="assign-box-head-image" v-bgimage="item.image" v-if="item.image" />

		<div class="assign-box-head-text">
			<div class="assign-box-head-text-name">{{ item.name }}</div>
			<div class="assign-box-head-text-variant" v-if="variant">{{ variant.name }}</div>
		</div>
				
	</div>

	<div class="assign-box-content">

		<div class="assign-box-content-text">Please select the badge(s) that will receive this add-on.</div>

		<div class="assign-box-content-item" v-on:click="onAssignToggle(badge.id)" v-tooltip="'Toggle badge selection'" :class="{'is-active': $_.contains(selected, badge.id)}" v-for="badge in badges" :key="badge.id">
			<div class="assign-box-content-item-name">{{ badge.name }}</div>
			<div class="assign-box-content-item-count" v-if="quantity[badge.id]">x {{ quantity[badge.id] }}</div>
			<app-icon v-on:click.native.stop="onAssignRemove(badge.id)" icon="minus" class="assign-box-content-item-action" v-if="quantity[badge.id]" />
			<app-icon v-on:click.native.stop="onAssignAdd(badge.id)" icon="plus" class="assign-box-content-item-action" />
		</div>

	</div>

	<app-panel-content :space="true">

		<app-button text="Confirm" :loading="is.loading" v-on:click="onConfirmClick" />

	</app-panel-content>

</app-panel>

</template>

<script>

import Vue from 'vue'

export default {

	props: ['item', 'variant'],

	data: function() {

		return {
			is: {
				loading: false,
				error: false
			},
			quantity: {},
			selected: []
		}

	},

	created: function() {

		this.$pubsub.$emit('offset', true)

		this.selected = []
		this.quantity = {}

		this.$_.each(this.$store.getters['basket/addons'], function(addon) {

			if (addon.item.id === this.item.id && (this.variant === undefined || this.variant.id === addon.variant.id)) {

				this.selected.push(addon.badge)
				Vue.set(this.quantity, addon.badge, addon.quantity)

			}

		}.bind(this))
		
	},

	beforeDestroy: function() {

		this.$pubsub.$emit('offset', false)

	},

	computed: {

		badges: function() {

			var badges = []

			badges.push({
				id: this.$store.getters['session/badge'].id,
				name: this.$store.getters['session/badge'].name,
			})

			return badges

		}

	},

	methods: {

		onConfirmClick: function() {

			this.$store.commit('basket/set', this.$_.filter(this.$store.getters['basket/items'], function(addon) {

				if (addon.item.id === this.item.id) {

					if (this.variant && addon.variant) {

						return this.variant.id !== addon.variant.id

					} else {

						return false

					}

				} else {

					return true

				}

			}.bind(this)))

			this.$_.each(this.selected, function(id) {

				this.$store.commit('basket/add', {
					item: this.item,
					variant: this.variant,
					badge: id,
					quantity: this.quantity[id]
				})

			}.bind(this))

			this.$emit('close')

		},

		onAssignToggle: function(id) {

			if (!this.quantity[id]) {

				Vue.set(this.quantity, id, 1)
				this.selected.push(id)

			}

		},

		onAssignAdd: function(id) {

			Vue.set(this.quantity, id, (this.quantity[id]) ? this.quantity[id] + 1 : 1)

			if (this.quantity[id] === 1) this.selected.push(id)

		},

		onAssignRemove: function(id) {

			this.quantity[id]--

			if (this.quantity[id] === 0) {

				this.$util.array.remove(this.selected, id)

			}

		}

	}

}

</script>

<style scoped>

.assign-box-head {
	padding: 10px 20px 20px 20px;
	display: flex;
}

.assign-box-head-image {
	width: 40px;
	height: 40px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	margin-right: 10px;
}

.assign-box-head-text {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: center;
}

.assign-box-head-text-name {
	font-size: 16px;
	color: #fff;
	font-weight: 700;
}

.assign-box-head-text-variant {
	font-size: 14px;
	color: #fff;
	font-weight: 400;
	margin-top: 4px;
}

.assign-box-content-text {
	color: #bfc4cb;
	font-weight: 300;
	font-size: 14px;
	line-height: 20px;
	padding: 0px 20px;
	margin-bottom: 20px;
}

.assign-box-content-item {
	font-size: 14px;
	color: #fff;
	font-weight: 400;
	padding: 10px 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}

.assign-box-content-item-name {
	flex-grow: 1;
	flex-basis: 0;
}

.assign-box-content-item-count {
	margin-left: 10px;
	margin-right: 10px;
}

.assign-box-content-item-action {
	margin-left: 10px;
}

.assign-box-content-item:hover {
	background-color: #387DD8;
	color: #fff;
}

.assign-box-content-item >>> .icon {
	font-size: 16px;
	color: #ccc;
}

.assign-box-content-item:hover >>> .icon {
	color: rgba(255, 255, 255, 0.25);
}

.assign-box-content-item.is-active >>> .icon {
	font-size: 16px;
	color: #ffffff;
}

.assign-box-content-item.is-active:hover >>> .icon {
	color: #fff;
}

</style>
